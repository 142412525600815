<template>
  <div class="one-last-step">
    <img
      class="one-last-step__icon"
      src="@/modules/questionnaire/assets/images/icons/medical-background.svg"
      alt="medical-background icon"
    />
    <h1 class="one-last-step__title u-typography-helvetica u-text-display u-text-display--s">
      {{ $t('prestartScreen2.title') }}
    </h1>
    <p class="one-last-step_description u-typography-helvetica u-text u-text--m">
      {{ $t('prestartScreen2.introText') }}
    </p>

    <div class="one-last-step__time-left-info time-left-info">
      <u-icon class="time-left-info__icon" name="clock" />
      <span class="time-left-info__text u-typography-helvetica u-text u-text--xs">{{
        $t('prestartScreen2.label')
      }}</span>
    </div>

    <div class="one-last-step__action-buttons page-action-buttons">
      <u-button class="page-action-buttons__button" kind="secondary" @click="$emit('back')">{{
        $t('action.back')
      }}</u-button>
      <u-button class="page-action-buttons__button" @click="$emit('continue')">{{
        $t('action.continue')
      }}</u-button>
    </div>
  </div>
</template>

<script>
import { UButton, UIcon } from 'universkin-shared';

export default {
  name: 'OneLastStepTemplate',
  components: { UButton, UIcon }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes';

.one-last-step {
  max-width: 621px;
  border-radius: 10px;
  text-align: center;

  &__icon {
    width: 90px;
    margin-bottom: 24px;
  }

  &__title {
    margin: 0 0 16px 0;
    color: var(--u-color-black);
  }

  &__description {
    margin-bottom: 16px;
    color: var(--u-color-grey-600);
  }

  &__time-left-info {
    position: relative;
    display: inline-block;
    padding: 2px 8px;
    margin-bottom: 32px;
    border: 2px solid var(--u-color-grey-300);
    border-radius: 8px;
  }

  &__action-buttons {
    display: flex;
    flex-direction: column-reverse;
  }
}

.time-left-info {
  &__text {
    margin-left: 20px;
    font-weight: 700;
    color: var(--u-color-grey-700);
  }

  &__icon {
    position: absolute;
    top: 5px;
  }
}

.page-action-buttons {
  &__button {
    flex: 1 1 0;

    &:last-child {
      margin-bottom: 8px;
    }
  }
}

@media (min-width: $desktop-start) {
  .one-last-step {
    padding: 16px 0;

    &__action-buttons {
      flex-direction: row;
    }
  }

  .page-action-buttons {
    &__button {
      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
